import React, { FormEvent, useEffect, useState } from 'react';
import { navigate, PageProps } from 'gatsby';
import Layout from '@/components/Layout';
import Seo from '@/components/Seo';
import { useUser } from '@/hooks/useUser';
import { useIntl } from 'react-intl';
import { Button, Input } from '@mikafi/ui';
import classNames from 'classnames';

const Login = ({}: PageProps) => {
  const { user, isLoading } = useUser();
  const intl = useIntl();
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<{ type?: string; content?: string }>({
    type: '',
    content: '',
  });

  const handleRegister = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    setMessage({});

    if (typeof window !== 'undefined') {
      // @ts-ignore
      if (window.fbq !== null && window.fbq !== undefined) {
        // @ts-ignore
        window.fbq('track', 'Subscribe');
      }
    }

    const response = await fetch(`/.netlify/functions/signup`, {
      method: 'POST',
      body: JSON.stringify({
        email,
        firstName,
        lastName,
        redirectTo: `${window?.location?.origin || ''}/${intl.locale}/account/`,
      }),
    });

    const data = await response.json();

    if (!response.ok) {
      setMessage({ type: 'error', content: data.message });
    }

    setMessage({
      type: 'note',
      content: intl.formatMessage({ id: 'emailLink' }),
    });

    setLoading(false);
  };

  useEffect(() => {
    if (user) {
      navigate(`/${intl.locale}/account/`);
    }
  }, [user]);

  if (isLoading) return null;

  return (
    <Layout
      alternatePages={[
        { locale: 'de', value: 'register' },
        { locale: 'en', value: 'register' },
      ]}
    >
      <Seo title="Register" />

      <div className="bg-white -mt-10">
        <div className="py-36 px-4">
          <div className="max-w-sm mx-auto">
            <h1 className="mb-8">{intl.formatMessage({ id: 'register' })}</h1>

            <p>{intl.formatMessage({ id: 'yourBenefitsRegister' })}</p>

            {message.content && (
              <div
                className={classNames('p-6 rounded-4xl mb-8', {
                  'bg-red-400': message.type === 'error',
                  'bg-green-400': message.type !== 'error',
                })}
              >
                {message.content}
              </div>
            )}

            <form onSubmit={handleRegister}>
              <div className="mb-4">
                <Input
                  className="w-full"
                  required
                  type="text"
                  placeholder={intl.formatMessage({ id: 'firstName' })}
                  onChange={e => setFirstName(e.target.value)}
                  name="firstName"
                  autoComplete="given-name"
                />
              </div>
              <div className="mb-4">
                <Input
                  className="w-full"
                  required
                  type="text"
                  placeholder={intl.formatMessage({ id: 'lastName' })}
                  onChange={e => setLastName(e.target.value)}
                  name="lastName"
                  autoComplete="family-name"
                />
              </div>
              <div className="mb-4">
                <Input
                  className="w-full"
                  required
                  type="email"
                  placeholder={intl.formatMessage({ id: 'email' })}
                  onChange={e => setEmail(e.target.value)}
                  name="email"
                />
              </div>

              <Button full type="submit" disabled={loading}>
                {intl.formatMessage({ id: 'signUp' })}
              </Button>
            </form>

            <div className="relative text-center after:border-b after:border-gray-500 after:absolute after:w-full after:inset-x-0 after:top-3 my-8">
              <span className="bg-white z-10 relative px-4 inline-block">
                {intl.formatMessage({ id: 'alreadyHaveAnAccount' })}
              </span>
            </div>

            <Button
              onClick={() => navigate(`/${intl.locale}/login/`)}
              className="w-full"
              variant="secondary"
            >
              {intl.formatMessage({ id: 'login' })}
            </Button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Login;
