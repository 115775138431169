import React, { FC } from 'react';
import LogoHorizontal from '@/assets/logo/logo-horizontal.svg';
import { Button } from '@mikafi/ui';
import Link from '@/components/Link';
import { useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import CoffeeClub from '@/assets/images/MCC.svg';

interface Props {
  links: {
    title: string;
    to: string;
  }[];
}

const Footer: FC<Props> = ({ links }) => {
  const intl = useIntl();

  return (
    <div className="flex flex-col justify-center items-center pt-12 sm:pt-24 pb-12 bg-black">
      <Helmet>
        <script src="https://js.createsend1.com/javascript/copypastesubscribeformlogic.js" />
      </Helmet>

      <div className="flex flex-col justify-center items-center  text-center pt-16 pb-8 m:pt-40 sm:pb-16 w-fit px-8 space-y-0 sm:space-y-12">
        <div className=" text-7xl sm:text-5xl md:text-9xl mb-8 text-white">
          Stay tuned!
        </div>

        <div className="flex flex-col space-y-6 sm:space-y-8 justify-center items-center">
          <h3 className="text-white">
            {intl.formatMessage({ id: 'stayUpToDate' })}
          </h3>

          <div className="w-fit">
            <form
              className="js-cm-form sm:flex"
              id="subForm"
              action="https://www.createsend.com/t/subscribeerror?description="
              method="post"
              data-id="191722FC90141D02184CB1B62AB3DC2645DCD1F9FA1FD22C81A5B10A22D913F9FE12C1695C6781B7882FB5099E6ED67F739B2249DF0F1FA97E984CED793A2D43"
            >
              <div className="sm:mr-4 sm:mb-0 mb-4">
                <input
                  autoComplete="Email"
                  aria-label="Email"
                  id="fieldEmail"
                  maxLength={200}
                  name="cm-jruyyuy-jruyyuy"
                  required
                  type="email"
                  placeholder={intl.formatMessage({ id: 'yourEmail' })}
                  className="w-full sm:w-80 js-cm-email-input qa-input-email rounded-full bg-[#1D1D1D] h-14 sm:h-full px-6 focus-visible:outline-0 placeholder:text-white/40 focus:bg-[#1A1A1A] text-white"
                />
              </div>
              <div>
                <Button full type="submit" variant="secondary">
                  {intl.formatMessage({ id: 'subscribe' })}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className=" mt-0 sm:mt-16 flex flex-col  md:flex-row justify-between md:items-center w-full px-8 md:px-12">
        <div className="mr-12 order-2 sm:order-1">
          <LogoHorizontal className="w-28 mt-8 md:mt-0" />
        </div>
        <div className="flex flex-wrap mt-8 md:mt-0 order-1 sm:order-2">
          {links?.map((link: any) => (
            <Link
              to={link.to}
              key={link.to}
              className="font-normal text-sm sm:text-md tracking-wide mr-4 md:mr-8 last:mr-0 my-3 sm:my-4 text-white no-underline border-b-black border-b hover:border-b-white hover:border-b "
            >
              {link.title}
            </Link>
          ))}
          <span className="text-sm sm:text-md tracking-wide mr-4 md:mr-8 last:mr-0 my-3 sm:my-4 text-white no-underline opacity-50">
            Copyright © {new Date().getFullYear()}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
