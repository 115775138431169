import React, { FC, ReactNode } from 'react';
import Footer from '@/components/Footer';
import Navigation from '@/components/Navigation';
import Link from '@/components/Link';
import { useIntl } from 'react-intl';
import { CookieBanner, useLocalStorage } from '@mikafi/ui';
import Cookies from 'js-cookie';

interface Props {
  hasFooter?: boolean;
  hasHeader?: boolean;
  showCart?: boolean;
  navChangeColor?: boolean;
  children?: ReactNode;
  variant?: 'light' | 'dark';
  alternatePages?: {
    locale: string;
    value: string;
  }[];
}

const Layout: FC<Props> = ({
  children,
  showCart = false,
  hasFooter = true,
  hasHeader = true,
  navChangeColor = true,
  variant = 'dark',
  alternatePages,
}) => {
  const [hasClosed, setHasClosed] = useLocalStorage('mikafi-banner', false);
  const intl = useIntl();

  const isSSR = typeof window === 'undefined';

  const acceptCookies = (accepted: boolean) => {
    setHasClosed(true);
    Cookies.remove('tracking-opt-out');

    if (!accepted && !isSSR) {
      Cookies.set('tracking-opt-out', 'true', {
        expires: 1000,
        path: '/',
      });
    }
  };

  const footerLinks: { title: string; to: { [key: string]: string } }[] = [
    {
      title: 'Impressum',
      to: {
        de: '/impressum/',
        en: '/impressum/',
      },
    },
    {
      title: intl.formatMessage({ id: 'contact' }),
      to: {
        de: '/kontakt/',
        en: '/contact/',
      },
    },
    {
      title: intl.formatMessage({ id: 'about' }),
      to: {
        de: '/ueber-uns/',
        en: '/about/',
      },
    },
    {
      title: 'Instagram',
      to: {
        de: 'https://www.instagram.com/mikaficoffee/',
        en: 'https://www.instagram.com/mikaficoffee/',
      },
    },
    {
      title: 'Linkedin',
      to: {
        de: 'https://www.linkedin.com/company/mikafi/',
        en: 'https://www.linkedin.com/company/mikafi/',
      },
    },
  ];

  return (
    <div>
      {hasHeader && (
        <header>
          <Navigation
            showCart={showCart}
            dark={variant === 'dark'}
            alternatePages={alternatePages}
            changeColor={navChangeColor}
          />
        </header>
      )}

      <main className={`${hasFooter ? 'bg-black' : 'bg-transparent'}`}>
        <div
          className={`relative overflow-hidden ${
            hasFooter ? 'rounded-b-4xl' : ''
          }`}
        >
          {children}
        </div>
      </main>

      {hasFooter && (
        <footer className="relative">
          <Footer
            links={footerLinks.map(link => ({
              to: link.to[intl.locale],
              title: link.title,
            }))}
          />
        </footer>
      )}

      {!hasClosed && !isSSR ? (
        <div className="z-50 fixed bottom-4 mx-6 md:mx-8 lg:mx-16">
          <CookieBanner
            handleOnClick={acceptCookies}
            buttonTextAccept={intl.formatMessage({
              id: 'accept',
            })}
            buttonTextDecline={intl.formatMessage({
              id: 'decline',
            })}
            // @ts-ignore
            text={intl.formatMessage(
              { id: 'terms' },
              { link: str => <Link to="/impressum">{str}</Link> },
            )}
          />
        </div>
      ) : null}
    </div>
  );
};
export default Layout;
